import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import CookerIcon from '../../assets/images/CookerIcon.svg';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const CombineIngredientsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box my={theme.spacing(2)} bgcolor='secondary.main'>
      <Box
        py={theme.spacing(2)}
        px={sm ? theme.spacing(0.5) : theme.spacing(4)}
        textAlign='center'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Typography variant='h4' color='textSecondary'>
          COLLECTED ALL INGREDIENTS ?<br /> COMBINE THEM TO COOK UP YOUR DISH
        </Typography>
        <img
          src={CookerIcon}
          alt='cooker icon'
          style={{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
          }}
          width={xs && '80%'}
        />
        <Button
          variant='contained'
          color='primary'
          style={{
            borderRadius: 12,
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
          }}
          fullWidth={xs && true}
        >
          <Typography variant='h4'>Combine</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CombineIngredientsSection;
