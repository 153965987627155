import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
  Hidden,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Error404Icon from '../assets/images/Error404Icon.svg';
import { theme } from '../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const Error404Page = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box px='7vw' py='15vh' minHeight='80vh' alignItems='center' display='flex'>
      <Grid container>
        <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
          <Typography variant='h1' style={{ marginBottom: theme.spacing(5) }}>
            Oops ! We burnt the Bread .
          </Typography>
          <Hidden smUp>
            <img
              src={Error404Icon}
              alt='error icon'
              style={{
                marginBottom: theme.spacing(5),
              }}
            />
          </Hidden>
          <Typography
            variant='body1'
            style={{ marginBottom: theme.spacing(5) }}
          >
            THE PAGE YOU ARE LOOKING FOR DOESN’T SEEM TO EXIST. LET’S GET YOU
            COOKING AGAIN.
          </Typography>
          <Button
            style={{
              borderRadius: 12,
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
              border: '1px solid black',
            }}
            fullWidth={xs && true}
            variant='outlined'
            color='secondary'
          >
            back to home
          </Button>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={6} style={{ textAlign: 'center' }}>
            <img src={Error404Icon} alt='error icon' height='100%' />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

export default Error404Page;
