import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Full from '../../assets/images/Full.svg';
import LogoBlack from '../../assets/images/LogoBlack.svg';
import CartIcon from '../../assets/images/CartIcon.svg';
import BowlIcon from '../../assets/images/BowlIcon.svg';
import OvenIcon from '../../assets/images/OvenIcon.svg';
import FoodItemsIcon from '../../assets/images/FoodItemsIcon.svg';
import PepperoniIcon from '../../assets/images/PepperoniIcon.svg';
import SteakIcon from '../../assets/images/SteakIcon.svg';
import ChickenIcon from '../../assets/images/ChickenIcon.svg';
import { theme } from '../../App';
import CustomButton from '../resuableComponents/CustomButton';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${Full})`,
  },
}));

const IntroductorySection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box p='10vw' className={classes.container}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Box textAlign='center' maxWidth='700px' mb={8}>
            <img
              src={LogoBlack}
              alt='One Rare logo black'
              style={{ maxWidth: '250px', marginBottom: theme.spacing(6) }}
            />
            <Typography variant='h6' style={{ marginBottom: theme.spacing(8) }}>
              Claim scrumptuous dishes by gathering ingredients and combining
              them as per Dish Recipes.Every NFT is 100% unique, custom-designed
              and deliciously awesome!
            </Typography>
          </Box>

          <Grid
            container
            justify='space-between'
            style={{ marginBottom: theme.spacing(14) }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={CartIcon}
                  alt='cart icon'
                  style={{ marginBottom: theme.spacing(6) }}
                />
                <Typography variant='subtitle1'>
                  BUY INGREDIENTS AS PER RECIPES
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={BowlIcon}
                  alt='bowl icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1'>
                  COMBINE TO MAKE DISHES
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={OvenIcon}
                  alt='oven icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1'>
                  STAKE DISHES TO EARN REWARDS
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              style={{ marginBottom: theme.spacing(4) }}
            >
              <Box textAlign='center'>
                <img
                  src={FoodItemsIcon}
                  alt='food items icon'
                  style={{ marginBottom: theme.spacing(4) }}
                />
                <Typography variant='subtitle1'>
                  TRADE DISHES ON MARKETPLACES
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box textAlign='center'>
            <Typography variant='h1' style={{ marginBottom: theme.spacing(8) }}>
              Recipes
            </Typography>
          </Box>
          <Grid container justify='space-between'>
            <Grid item xs={12} lg={4} md={6} sm={12}>
              <Box textAlign='center'>
                <img
                  src={PepperoniIcon}
                  alt='cart icon'
                  style={{ marginBottom: theme.spacing(3) }}
                />
                <Typography
                  variant='h4'
                  style={{
                    marginBottom: theme.spacing(3),
                  }}
                >
                  PEPPERONI PIZZA
                </Typography>
                <CustomButton>VIEW RECIPE</CustomButton>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} md={6} sm={12}>
              <Box textAlign='center'>
                <img
                  src={SteakIcon}
                  alt='cart icon'
                  style={{ marginBottom: theme.spacing(3) }}
                />
                <Typography
                  variant='h4'
                  style={{
                    marginBottom: theme.spacing(3),
                  }}
                >
                  BEEF STEAK
                </Typography>
                <CustomButton>VIEW RECIPE</CustomButton>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} md={6} sm={12}>
              <Box textAlign='center'>
                <img
                  src={ChickenIcon}
                  alt='cart icon'
                  style={{ marginBottom: theme.spacing(3) }}
                />
                <Typography
                  variant='h4'
                  style={{
                    marginBottom: theme.spacing(3),
                  }}
                >
                  CHICKEN TAGINE
                </Typography>
                <CustomButton>VIEW RECIPE</CustomButton>
              </Box>
            </Grid>
          </Grid>
          <Button
            variant='outlined'
            style={{
              backgroundColor: 'white',
              paddingLeft: '2rem',
              paddingRight: '2rem',
              borderRadius: '40px',
              border: '1px solid black',
            }}
          >
            See more
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default IntroductorySection;
