import React from 'react';
import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import StakeItem from './StakeItem';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const StakeItemsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { recipesList } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container spacing={!xs && 10}>
      {recipesList.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={item.name}>
          <StakeItem item={item} index={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default StakeItemsSection;
