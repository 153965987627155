import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {useHistory, useParams} from "react-router-dom";
import PotatoIcon from '../assets/images/PotatoIcon.svg';
import { theme } from '../App';
import {getIngredientById} from "../reducers/UiReducer";
import Error404 from './Error404Page';
import gameService from '../services/gameService';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const IngredientSelectedPage = () => {
  const store = useSelector((state) => state);
	const history=useHistory();
	const {id}=useParams();
	const {selectedIngredient:item,error} = store.ui;
	const dispatch=useDispatch();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));


	useEffect(()=>{
		dispatch(getIngredientById(id));
	},[]);

	const buyItem=async()=>{
		await gameService.purchaseIngredient(item.type,item.tokenId,item.price);
	}

	if(error)
		return <Error404/>

  return (
    <Box px='7vw' py='10vh'>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Box display='flex' justifyContent='center'>
            <img src={`${item.image}`} style={{width:'228px',height:'200px'}} alt='ingrediant icon' />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant='h1'>{item.name}</Typography>
          <Divider classes={{ root: classes.root }} />
          <Typography variant='subtitle1'>PRICE: {item.price} MATIC</Typography>
          <Divider classes={{ root: classes.root }} />
          <Box mt={5}>
            <Typography variant='body1'>{item.description}</Typography>
          </Box>
          <Box mt={2} mb={5}>
            <Typography variant='subtitle1'>{item.type}</Typography>
          </Box>
          <Button
            variant='outlined'
            fullWidth
            style={{
              border: '1px solid black',
              backgroundColor: theme.palette.primary.main,
              borderRadius: 12,
            }}
						onClick={buyItem}
          >
            BUY NOW
          </Button>
        </Grid>
      </Grid>
      {item && item.dishes && item.dishes.length>0 && <Box mt={xs ? 15 : 20}>
        <Typography variant={xs ? 'subtitle1' : 'h4'}>
          THIS INGREDIENT CAN BE USED TO MAKE :
        </Typography>
        <Box display='flex' flexWrap='wrap'>
					{item.dishes.map((dish)=><Button
            style={{
              border: '1px solid black',
              borderRadius: 12,
              marginTop: theme.spacing(2),
              minHeight: '3rem',
              marginRight: '1rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
            }}
            variant='outlined'
						onClick={()=>history.push(`/recipes/${dish.tokenId}`)}
          >
            <Typography variant='subtitle1'>{dish.name}</Typography>
          </Button>)}
        </Box>
      </Box>}
    </Box>
  );
};

export default IngredientSelectedPage;
