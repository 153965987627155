import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../resuableComponents/CustomButton';
import { theme } from '../../App';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
  },
}));

const IngredientsItem = ({ item, index }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      textAlign='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <img
        src={item.image}
        alt='ingredient icon'
        style={{ marginBottom: theme.spacing(1.5), width: xs ? '80%' : '100%' }}
      />
      <Tooltip
        title={item.name}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography variant='h4'>{item.name}</Typography>
      </Tooltip>
      <Divider
        classes={{ root: classes.root }}
        style={{ width: xs ? '80%' : '100%' }}
      />
      <Typography
        variant='subtitle1'
        style={{ marginBottom: theme.spacing(2.5) }}
      >
        {item.price}
      </Typography>
      <CustomButton
        style={{ marginBottom: theme.spacing(1.5) }}
        onClick={() => history.push(`/ingredients/${item.tokenId}`)}
      >
        BUY NOW
      </CustomButton>
      <CustomButton disabled>VIEW INFO</CustomButton>
    </Box>
  );
};

export default IngredientsItem;
