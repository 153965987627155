import React, { useEffect } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import IngredientsIntroSection from '../components/IngredientsComponents/IngredientsIntroSection';
import TabSection from '../components/IngredientsComponents/IngredientsTabSection';
import FilterSection from '../components/IngredientsComponents/IngredientsFilterSection';
import ItemsSection from '../components/IngredientsComponents/IngredientsItemsSection';
import { getIngredientsDispatch } from '../reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const IngredientsPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getIngredientsDispatch());
  }, []);

  return (
    <Box px='7vw' py='5vh'>
      <IngredientsIntroSection />
      <TabSection />
      <FilterSection />
      <ItemsSection />
    </Box>
  );
};

export default IngredientsPage;
