import React from 'react';
import { Grid, Box, makeStyles, Hidden, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import InstagramLogo from '../../assets/images/InstagramLogo.svg';

const useStyles = makeStyles((theme) => ({
  flexDirection: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
}));

const SocialMediaItems = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box className={classes.flexDirection}>
      <Box display='flex'>
        <img
          src={InstagramLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
        />
        <Hidden xsDown>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              DOCS
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex'>
        <img
          src={InstagramLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
        />
        <Hidden xsDown>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              GITHUB
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex'>
        <img
          src={InstagramLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
        />
        <Hidden xsDown>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              TELEGRAM
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex'>
        <img
          src={InstagramLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
        />
        <Hidden xsDown>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              TWITTER
            </Typography>
          </Link>
        </Hidden>
      </Box>
      <Box display='flex'>
        <img
          src={InstagramLogo}
          alt='instagram logo'
          style={{ marginRight: 10 }}
        />
        <Hidden xsDown>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Typography
              variant='body2'
              color='textPrimary'
              className={classes.linkItems}
            >
              INSTAGRAM
            </Typography>
          </Link>
        </Hidden>
      </Box>
    </Box>
  );
};

export default SocialMediaItems;
