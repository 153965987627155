import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RecipesIntroSection from '../components/recipesComponents/RecipesIntroSection';
import RecipesFilterSection from '../components/recipesComponents/RecipesFilterSection';
import RecipesItemsSection from '../components/recipesComponents/RecipesItemsSection';
import { getRecipesDispatch } from '../reducers/UiReducer';

const useStyles = makeStyles((theme) => ({}));

const RecipesPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

	useEffect(()=>{
		dispatch(getRecipesDispatch());
	},[]);

  return (
    <Box px='7vw' py='5vh'>
      <RecipesIntroSection />
      <RecipesFilterSection />
      <RecipesItemsSection />
    </Box>
  );
};

export default RecipesPage;
