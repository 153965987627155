import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Divider,
  Tooltip,
	useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../resuableComponents/CustomButton';
import { theme } from '../../App';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    width: 180,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
  },
}));

const RecipesItem = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();
	const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box
      textAlign='center'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <img src={item.image} alt='recipie icon' style={{ marginBottom: theme.spacing(1.5), width: xs ? '80%' : '100%' }}/>
      <Tooltip
        title={item.name}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <Typography variant='h4' style={{ paddingTop: theme.spacing(3) }}>
          {item.name.toUpperCase()}
        </Typography>
      </Tooltip>
      <CustomButton
        style={{
          marginBottom: theme.spacing(3),
          marginTop: theme.spacing(1.5),
        }}
        onClick={() => history.push(`/recipes/${item.id}`)}
      >
        View Recipe
      </CustomButton>
    </Box>
  );
};

export default RecipesItem;
