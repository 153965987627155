import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import PepperoniPizzaIcon from '../../assets/images/PepperoniPizzaIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const RecipesIntroSection = ({selectedRecipe,recipeIngredients}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { recipe } = store.ui;
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      justify='space-between'
      style={{ paddingBottom: '15vh', paddingTop: '10vh' }}
    >
      <Grid item xs={12} md={7}>
        <Box>
          <Typography variant='h1'>{selectedRecipe.name}</Typography>
          <Divider classes={{ root: classes.root }} />
          <Typography
            variant='subtitle1'
            style={{
              marginBottom: theme.spacing(5),
            }}
          >
            ITALIAN CUISINE
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            {selectedRecipe.description}
          </Typography>
          <Hidden mdUp>
            <img
              src={`${selectedRecipe.image}`}
              alt='pizza icon'
              height='auto'
              width='100%'
              style={{
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            />
          </Hidden>
          <Box
            display='flex'
            style={{
              marginTop: theme.spacing(10),
            }}
          >
            <Typography variant='h4' style={{ marginRight: theme.spacing(1) }}>
              RECIPE
            </Typography>
            <Box display='flex' flexDirection='column'>
              {recipeIngredients && recipeIngredients.map((item, index) => (
                <Box display='flex' alignItems='center'>
                  <Box
                    style={{ width: 1, height: 40, background: 'black' }}
                  ></Box>
                  <Box
                    style={{
                      width: 10,
                      height: 1,
                      background: 'black',
                      marginRight: theme.spacing(1),
                    }}
                  ></Box>
                  <Typography>{item.name}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={4}>
          <img
            src={`${selectedRecipe.image}`}
            alt='pizza icon'
            height='auto'
            width='100%'
          />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default RecipesIntroSection;
