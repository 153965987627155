import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PotatoIcon from '../assets/images/PotatoIcon.svg';
import PepperoniPizzaIcon from '../assets/images/PepperoniPizzaIcon.svg';
import { axiosInstance } from '../Axios';
import ethersServiceProvider from '../services/ethersServiceProvider';

const initialState={
	navItems: ['INGREDIENTS', 'RECIPES', 'STAKE', 'REWARDS'],
	headerMenuExpanded: false,
	selectedIngredient: 'All Ingredients',
	loading: false,
	error: null,
	ingredientsList: [],
	recipesList: [],
	recipe: [],
	selectedReward: 'Ingredients',
	rewardsList: [],
	selectedDashboard: 'Ingredients',
	modalOpen: false,
	temp: [],
	selectedIngredient:{},
	selectedRecipe:{},
	recipeIngredients:[]
}

export const getIngredientById=createAsyncThunk(
  'getIngredientById',
  async (id) => {
    const {data} = await axiosInstance.get(`/api/ingredient/${id}`);
		return data;
  },
);
export const getRecipieById=createAsyncThunk(
  'getRecipieById',
  async (id) => {
    const {data} = await axiosInstance.get(`/api/dish/${id}`);
		return data;
  },
);

export const getTokensOwned=createAsyncThunk(
  'getTokensOwned',
  async () => {
    const data = await ethersServiceProvider.getAllTokensOwned();
		const res=await Promise.all(data.map((id)=>axiosInstance.get(`/api/ingredient/${id}`)));
		return res.map(({data})=>data);
  },
);

// export const getRecipieIngredients=createAsyncThunk(
//   'getRecipieIngredients',
//   async (id) => {
// 		const ingredientList=await Promise.all(id.map(async (_id)=>{
// 			const {data} = await axiosInstance.get(`/api/ingredient/${_id}`);
// 			return data;
// 		}));
// 		return ingredientList;
//   },
// );

export const getIngredientsDispatch = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const { data } = await axiosInstance.get('/api/ingredients');
    dispatch(getIngredientsSuccess(data));
  } catch (error) {
    dispatch(
      getIngredientsFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const getRecipesDispatch = () => async (dispatch) => {
  try {
    dispatch(setLoading());
    const { data } = await axiosInstance.get('/api/dishes');
    dispatch(getRecipesSuccess(data));
  } catch (error) {
    dispatch(
      getRecipesFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const getDishById = () => async(dispatch)=>{}

const UiReducer = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleHeaderMenuExpanded(state) {
      state.headerMenuExpanded = !state.headerMenuExpanded;
    },
    selectIngredient(state, action) {
      state.selectedIngredient = action.payload;
    },
    selectReward(state, action) {
      state.selectedReward = action.payload;
    },
    selectDashboard(state, action) {
      state.selectedDashboard = action.payload;
    },
    setModalOpen(state) {
      state.modalOpen = !state.modalOpen;
    },
    setLoading(state, action) {
      state.loading = true;
    },
    getIngredientsSuccess(state, action) {
      state.loading = false;
      state.ingredientsList = action.payload;
    },
    getIngredientsFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getRecipesSuccess(state, action) {
      state.loading = false;
      state.recipesList = [...action.payload];
    },
    getRecipesFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
		getSelectedIngredientFail(state,action){
			state.error=action.payload;
			state.loading=false;
		}
  },
	extraReducers:(builder)=>{
		builder.addCase(getIngredientById.pending,(state,action)=>{
			state.loading=true;
			state.error=false;
		});
		builder.addCase(getIngredientById.fulfilled,(state,action)=>{
			state.loading=false;
			state.selectedIngredient=action.payload;
		});
		builder.addCase(getIngredientById.rejected,(state,action)=>{
			state.loading=false;
			state.error=true;
		});
		builder.addCase(getRecipieById.pending,(state,action)=>{
			state.loading=true;
			state.error=false;
		});
		builder.addCase(getRecipieById.fulfilled,(state,action)=>{
			state.loading=false;
			state.selectedRecipe=action.payload;
		});
		builder.addCase(getRecipieById.rejected,(state,action)=>{
			state.loading=false;
			state.error=true;
		});
		builder.addCase(getTokensOwned.fulfilled,(state,action)=>{
			state.loading=false;
			state.rewardsList=action.payload;
		});

		// builder.addCase(getRecipieIngredients.fulfilled,(state,action)=>{
		// 	state.loading=false;
		// 	state.recepieIngredients=action.payload;
		// })
	}
});

const { actions } = UiReducer;

export const {
  toggleHeaderMenuExpanded,
  selectIngredient,
  selectReward,
  selectDashboard,
  setModalOpen,
  setLoading,
  getIngredientsSuccess,
  getIngredientsFail,
  getRecipesSuccess,
  getRecipesFail,
	setSelectedIngredient,
	getSelectedIngredientFail
} = actions;

export default UiReducer;
