import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  fontSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
}));

const PrelaunchFAQSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box p='10vw' bgcolor='secondary.main'>
      <Container>
        <Box textAlign='center' mb={8}>
          <Typography
            variant='h1'
            color='textSecondary'
            className={classes.fontSize}
          >
            Frequently asked Questions
          </Typography>
        </Box>
        <Grid container justify='space-between'>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                When will OneRare be launched?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                OneRare will be launched on OxPolygon in July 2021, followed by
                an IDO in August.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How do I claim my ORA tokens?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                The Token Claim process will be relased on our social media
                channels. Be sure to follow us there to get updates on the token
                claim and other project related news.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How can I participate in the ORA Token Drop?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Sign up in the Token Claim section & invite your friends to
                OneRare to climb up on the leaderboard.
              </Typography>
              <br />
              <Typography variant='body1' color='textSecondary'>
                Due to limited slots, only the top 5000 participants ranked by
                points will be eligible for the Token drop.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How can I get involved with OneRare ?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Join us on Discord, suggest Dishes & tell us how you would like
                to contribute. Submit artwork and help us decide which recipes
                to feature on the OneRare platform.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PrelaunchFAQSection;
