import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  fontSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
}));

const FAQSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box p='10vw' bgcolor='secondary.main'>
      <Container>
        <Box textAlign='center' mb={8}>
          <Typography
            variant='h1'
            color='textSecondary'
            className={classes.fontSize}
          >
            Frequently asked Questions
          </Typography>
        </Box>
        <Grid container justify='space-between'>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                What is Onerare?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Onerare is a Defi + NFT game based on Design scarcity and
                economics. The main objective is to combine NFT ingredients as
                per Recipes to claim unique Dish NFTs.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.cyan.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How do I make 💰 with One Rare ?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                Get in quick & buy early generation Dishes at low prices. With
                every sale, the cost of Special ingredients will go up ! Also
                make 💰💰 by staking dishes to earn ORE coins and ingredients.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.pink.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How do I make Dishes?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                It’s very simple- Follow the Dish Recipe and source Ingredients
                from the Pantry/Specials Store. Once you have collected them
                all, combine the Recipe to claim your Dish NFT. Each Dish NFT is
                unique - meaning that there will ever be only one such Dish NFT
                ever minted.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Box display='flex' flexDirection='column' mb={8}>
              <Typography
                variant='body1'
                style={{
                  color: theme.palette.green.main,
                  marginBottom: theme.spacing(1),
                }}
              >
                How does the ORE token work ?
              </Typography>
              <Typography variant='body1' color='textSecondary'>
                All monies paid by users to purchase ingredients will be used to
                buy and burn ORE tokens, thereby reducing the supply. ORE tokens
                can also be used to join the OneRare Dao, suggest Recipes and
                decide which NFTs go live next!
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQSection;
