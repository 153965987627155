import React from 'react';
import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import IngredientsItem from './IngredientsItem';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const IngredientsItemsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ingredientsList } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container spacing={!xs && 10}>
      {ingredientsList.map((item, index) => (
        <Grid item xs={6} sm={4} md={3} key={item.id}>
          <IngredientsItem item={item} index={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default IngredientsItemsSection;
