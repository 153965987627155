import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  Divider,
  Hidden,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'black',
    height: 2,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const RecipesIntroSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      justify='space-between'
      style={{ paddingBottom: '15vh', paddingTop: '10vh' }}
    >
      <Grid item xs={12} md={7}>
        <Box>
          <Typography variant='h1' style={{ textAlign: sm && 'center' }}>
            Recipes
          </Typography>
          <Hidden smDown>
            <Divider classes={{ root: classes.root }} />
          </Hidden>
          <Hidden mdUp>
            <Box height='100%' bgcolor='#C4C4C4'>
              hey
            </Box>
          </Hidden>
          <Typography
            variant='body1'
            style={{ paddingRight: theme.spacing(1) }}
          >
            Welcome to the OneRare recipe section. Find your favorite dishes and
            claim them by combining all the ingredients in the recipe.
          </Typography>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item xs={12} sm={4}>
          <Box height='100%' bgcolor='#C4C4C4'>
            hey
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default RecipesIntroSection;
