import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { isMobile } from 'react-device-detect';
import { useEffect, useState } from 'react';

import { SUPPORTED_CHAINS } from '../../config';
import ethersServiceProvider from '../../services/ethersServiceProvider';
import { useHistory } from 'react-router-dom';

const injected = new InjectedConnector({ supportedChainIds: SUPPORTED_CHAINS });

export const useMetamask = () => {
  const { active, activate, account } = useWeb3React();
  const [tried, setTried] = useState(false);
	const history= useHistory();

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true);
          });
        }
        setTried(true);
      }
    });
  }, [activate]);

  useEffect(() => {
    if (active) {
      setTried(true);
    }
  }, [active]);

  useEffect(() => {
    ethersServiceProvider.setCurrentAccount(account);
  }, [account]);

  const connectMetamask = async () => {
    try {
      await activate(injected, undefined, true);
			history.replace("/admin");
      console.log('Metamask loaded');
    } catch (err) {
      if (err instanceof UnsupportedChainIdError) {
        await activate(injected);
      } else {
        console.log(err, 'Error in connecting metamask');
      }
    }
  };

  return {
    active,
    tried,
    connectMetamask,
  };
};
