import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Dialog,
  useMediaQuery,
  Typography,
  Hidden,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setModalOpen } from '../../reducers/UiReducer';
import { theme } from '../../App';
import SuccessfulOrder from '../../assets/images/SuccessfulOrder.svg';
import CancelIcon from '../../assets/images/CancelIcon.svg';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const CustomModal = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { modalOpen } = store.ui;

  const handleClose = () => {
    dispatch(setModalOpen());
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='simple-dialog-title'
      open={modalOpen}
      maxWidth='md'
    >
      <Box
        border='10px solid #D1FF1A'
        px={!sm ? '5vw' : '5vw'}
        py={!sm ? '10vh' : '5vh'}
      >
        <Box
          style={{ position: 'absolute', right: '3%', top: '5%' }}
          onClick={handleClose}
        >
          <img alt='cancel icon' src={CancelIcon} />
        </Box>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={9}
            style={{
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '2vh',
              paddingBottom: '2vh',
            }}
          >
            <Typography variant='h2' style={{ marginBottom: theme.spacing(2) }}>
              Thank you for your Order !
            </Typography>
            <Hidden smUp>
              <img
                src={SuccessfulOrder}
                alt='error icon'
                style={{
                  marginBottom: theme.spacing(5),
                  objectFit: 'contain',
                }}
              />
            </Hidden>
            <Typography
              variant='body1'
              style={{ marginBottom: theme.spacing(5) }}
            >
              THE INGREDIENT HAS BEEN ADDED TO YOUR WALLET.
            </Typography>
            <Button
              style={{
                borderRadius: 12,
                paddingLeft: theme.spacing(5),
                paddingRight: theme.spacing(5),
                border: '1px solid black',
              }}
              fullWidth={sm && true}
              variant='outlined'
              color='secondary'
            >
              VIEW PURCHASES
            </Button>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={12} sm={3} style={{ textAlign: 'center' }}>
              <img
                src={SuccessfulOrder}
                alt='error icon'
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CustomModal;
