import React from 'react';
import { Grid, Box, makeStyles, useMediaQuery } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RecipesItem from './RecipesItem';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const RecipesItemsSection = () => {
  const store = useSelector((state) => state);
  const { recipesList } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container spacing={!xs && 10}>
      {recipesList.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={item.name}>
          <RecipesItem item={item} index={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default RecipesItemsSection;
