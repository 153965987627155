import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RewardsItem from './RewardsItem';
import { theme } from '../../App';
import CustomButton from '../resuableComponents/CustomButton';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const RewardsItemsSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { rewardsList, selectedReward } = store.ui;
  const matches = useMediaQuery('(min-width:940px)');
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container spacing={!xs && 10}>
      {selectedReward === 'Ingredients' ? (
        rewardsList.map((item, index) => (
          <Grid item xs={6} sm={4} md={3} key={item.name}>
            <RewardsItem item={item} index={index} />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <Box
              display='flex'
              flexDirection={xs ? 'column' : 'row'}
              alignItems='center'
            >
              <Typography variant='h4' color='initial'>
                YOUR UNCLAIMED TOKENS
              </Typography>
              <Typography variant='h4' color='initial'>
                xxx
              </Typography>
            </Box>
            <CustomButton>Claim</CustomButton>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RewardsItemsSection;
