import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import PrelaunchBackground from '../../assets/images/PrelaunchBackground.svg';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${PrelaunchBackground})`,
  },
}));

const PrelaunchClaimTokensSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box p='10vw' className={classes.container}>
      <Container>
        <Box display='flex' flexDirection='column' alignItems='center'>
          {!xs ? (
            <Box textAlign='center' mb={8}>
              <Typography
                variant='h1'
                style={{ marginBottom: theme.spacing(6) }}
              >
                Claim your Tokens
              </Typography>
              <Typography variant='h6'>
                For OneRare, Community comes first.
              </Typography>
              <Typography variant='h6'>
                Before Team, before Investors & before anything else.
              </Typography>
              <Typography
                variant='h6'
                style={{ marginBottom: theme.spacing(8) }}
              >
                As a thank you to our earliest backers, we are giving away 2.5M
                Tokens for free.
              </Typography>
            </Box>
          ) : (
            <Box textAlign='center' mb={8}>
              <Typography
                variant='h2'
                style={{ marginBottom: theme.spacing(6) }}
              >
                Claim your Tokens
              </Typography>
              <Typography variant='h6'>
                For OneRare, Community comes first. Before Team, before
                Investors & before anything else.
              </Typography>
              <br />
              <Typography
                variant='h6'
                style={{ marginBottom: theme.spacing(8) }}
              >
                As a thank you to our earliest backers, we are giving away 2.5M
                Tokens for free.
              </Typography>
            </Box>
          )}

          <Box display='flex' flexDirection='column' style={{}} mb={8}>
            <Typography
              variant='h3'
              style={{
                marginBottom: theme.spacing(3),
                textAlign: sm && 'center',
              }}
            >
              Follow 3 Simple Steps{sm && <br />}to claim your Share
            </Typography>
            <Box>
              <Typography
                variant='body1'
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: theme.spacing(1.5),
                  marginBottom: theme.spacing(1.5),
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{ display: 'inline', width: '20%' }}
                >
                  Step 1 {''}
                </Typography>
                : Fill the OneRare Token Claim Form
              </Typography>
              <Typography
                variant='body1'
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: theme.spacing(1.5),
                  marginBottom: theme.spacing(1.5),
                }}
              >
                <Typography variant='subtitle1' style={{ display: 'inline' }}>
                  Step 2 {''}
                </Typography>
                : Invite your friends to OneRare to gain points
              </Typography>
              <Typography
                variant='body1'
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: theme.spacing(1.5),
                  marginBottom: theme.spacing(1.5),
                }}
              >
                <Typography
                  variant='subtitle1'
                  style={{ display: 'inline', width: '20%' }}
                >
                  Step 3 {''}
                </Typography>
                : Join us on Twitter, Discord & Telegram to stay updated
              </Typography>
            </Box>
          </Box>

          <Grid container>
            <Grid
              item
              sm={12}
              lg={6}
              style={{
                paddingRight: !xs && theme.spacing(5),
                paddingLeft: !xs && theme.spacing(5),
              }}
            >
              <Typography
                variant='h3'
                style={{
                  marginBottom: theme.spacing(3),
                  textAlign: sm && 'center',
                }}
              >
                Token Allocation
              </Typography>
              <Typography
                variant='body1'
                style={{ marginBottom: theme.spacing(3) }}
              >
                OneRare Tokens will be allocated to Members who rank in Top 5000
                in our Leaderboard.
              </Typography>
              <Typography
                variant='body1'
                style={{ marginBottom: theme.spacing(3) }}
              >
                Get 1 Point for Signing up & 1 Point for each Referral. Move up
                the Leaderboard to win bigger Rewards.
              </Typography>
              <Typography
                variant='body1'
                style={{ marginBottom: theme.spacing(4) }}
              >
                In case of tie, priority will be given to the Member who joined
                first.
              </Typography>
              <Box display='flex' mb={1}>
                <Box width='30%' bgcolor='secondary.main'>
                  <Typography
                    variant='body2'
                    color='primary'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    RANK
                  </Typography>
                </Box>
                <Box width='70%' style={{ backgroundColor: '#6E6E6E' }}>
                  <Typography
                    color='textSecondary'
                    variant='body2'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    REWARDS
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' mb={1}>
                <Box width='30%' bgcolor='primary.main'>
                  <Typography
                    variant='body2'
                    color='secondary'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    1 - 10
                  </Typography>
                </Box>
                <Box
                  width='70%'
                  style={{
                    backgroundColor: theme.palette.background.footerGrey,
                  }}
                >
                  <Typography
                    color='textPrimary'
                    variant='body2'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    10,000 ORA TOKENS + 200 MATIC
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' mb={1}>
                <Box width='30%' bgcolor='primary.main'>
                  <Typography
                    variant='body2'
                    color='secondary'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    11 - 100
                  </Typography>
                </Box>
                <Box
                  width='70%'
                  style={{
                    backgroundColor: theme.palette.background.footerGrey,
                  }}
                >
                  <Typography
                    color='textPrimary'
                    variant='body2'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    2,000 ORA TOKENS
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' mb={1}>
                <Box width='30%' bgcolor='primary.main'>
                  <Typography
                    variant='body2'
                    color='secondary'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    101 - 1000
                  </Typography>
                </Box>
                <Box
                  width='70%'
                  style={{
                    backgroundColor: theme.palette.background.footerGrey,
                  }}
                >
                  <Typography
                    color='textPrimary'
                    variant='body2'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    500 ORA TOKENS
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' mb={1}>
                <Box width='30%' bgcolor='primary.main'>
                  <Typography
                    variant='body2'
                    color='secondary'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    1000 - 5000
                  </Typography>
                </Box>
                <Box
                  width='70%'
                  style={{
                    backgroundColor: theme.palette.background.footerGrey,
                  }}
                >
                  <Typography
                    color='textPrimary'
                    variant='body2'
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      paddingTop: theme.spacing(1),
                      paddingBottom: theme.spacing(1),
                    }}
                  >
                    250 ORA TOKENS
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={12} lg={6}>
              <div data-vl-widget='popupTrigger'></div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PrelaunchClaimTokensSection;
