import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SplashImage from '../assets/images/SplashImage.svg';
import FAQSection from '../components/homeComponents/FAQSection';
import IntroductorySection from '../components/homeComponents/IntroductorySection';
import CustomModal from '../components/resuableComponents/CustomModal';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const HomePage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box>
      <img
        src={SplashImage}
        alt='One Rare splash'
        style={{ width: '100%', height: '100%', display: 'block' }}
      />
      <IntroductorySection />
      <FAQSection />
      <CustomModal />
    </Box>
  );
};

export default HomePage;
