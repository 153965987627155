import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SplashImagePrelaunch from '../assets/images/SplashImagePrelaunch.svg';
import PrelaunchFAQSection from '../components/prelaunchHomeComponents/PrelaunchFAQSection';
import PrelaunchIntroductorySection from '../components/prelaunchHomeComponents/PrelaunchIntroductorySection';
import PrelaunchClaimTokensSection from '../components/prelaunchHomeComponents/PrelaunchClaimTokensSection';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const HomePage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box>
      <img
        src={SplashImagePrelaunch}
        alt='One Rare splash'
        style={{ width: '100%', height: '100%', display: 'block' }}
      />

      <PrelaunchIntroductorySection />
      <PrelaunchClaimTokensSection />
      <PrelaunchFAQSection />
    </Box>
  );
};

export default HomePage;
