import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import IngredientsItem from '../IngredientsComponents/IngredientsItem';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const LetsGetStartedSection = ({recipeIngredients}) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box>
      <Box
        textAlign='center'
        mb={theme.spacing(1.5)}
        display={xs && 'flex'}
        flexDirection={xs && 'column'}
        justifyContent={xs && 'center'}
      >
        <Typography variant='h1'>Let's get Cooking</Typography>
        <Typography variant='h5'>
          UNLOCK THIS DISH BY COLLECTING THE INGREDIENTS
        </Typography>
      </Box>
      <Grid container spacing={!xs && 10}>
        {recipeIngredients && recipeIngredients.map((item, index) => (
          <Grid item xs={6} sm={4} md={3} key={item.name}>
            <IngredientsItem item={item} index={index} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LetsGetStartedSection;
