import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import ButtonBackground from '../../assets/images/ButtonBackground.svg';
import DisabledButtonBackground from '../../assets/images/DisabledButtonBackground.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    // paddingLeft: '3rem',
    // paddingRight: '3rem',
    width: 178.21,
    [theme.breakpoints.down('xs')]: {
      width: 102,
    },
    // height: 45,
    borderRadius: '40px',
    marginBottom: theme.spacing(6),
  },
}));

const CustomButton = ({ children, style, disabled, onClick }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  console.log(theme.breakpoints.down('xs'));

  return (
    <Button
      variant='contained'
      classes={{ root: classes.root }}
      style={{
        backgroundImage: `url(${
          disabled ? DisabledButtonBackground : ButtonBackground
        })`,
        backgroundColor: disabled ? '#b2b3b3' : '#000000',
        ...style,
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
