import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Meta from './Meta';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const Layout = ({ children, noContainer, title, description, keywords }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <Meta title={title} description={description} keywords={keywords} />
      <Header  />
      <main>{noContainer ? children : <Container>{children}</Container>}</main>
      <Footer  />
    </>
  );
};

export default Layout;
