import React from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Container,
  Button,
  Typography,
  Hidden,
  useMediaQuery,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import LogoWhite from '../../assets/images/LogoWhite.svg';
import { toggleHeaderMenuExpanded } from '../../reducers/UiReducer';
import HamburgerMenuWhite from '../../assets/images/HamburgerMenuWhite.svg';
import {useMetamask} from "../hooks/useMetamask";

const useStyles = makeStyles((theme) => ({
  linkItems: {
    fontWeight: 'bold',
  },
  paper: {
    top: '67px !important',
    left: 'unset !important',
    right: '0 !important',
    width: '45%',
    borderRadius: '0',
    backgroundColor: 'black',
    transformOrigin: '16px -1px !important',
  },
  listItem: {
    justifyContent: 'center',
  },
}));

const Header = ({ prelaunch }) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
	const { active, connectMetamask, tried } = useMetamask();
  const { navItems, headerMenuExpanded } = store.ui;
  const history = useHistory();
  const matches = useMediaQuery('(min-width:830px)');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (word) => {
    dispatch(toggleHeaderMenuExpanded());
    history.push(word);
  };

  return (
    <Box bgcolor='secondary.main'>
      <header>
        <Container>
          <Grid
            style={{
              color: theme.palette.primary.contrastText,
              display: 'flex',
              paddingTop: '1rem',
              paddingBottom: '1rem',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <Link to={'/'} style={{ display: 'flex', marginRight: '2rem' }}>
                <img src={LogoWhite} alt='One Rare logo white' />
              </Link>
              {!prelaunch && (
                <Box hidden={!matches}>
                  {/* NECCESSARY FOR HIDING */}
                  <Box display='flex'>
                    <Link
                      to='/'
                      style={{ textDecoration: 'none', marginRight: '2rem' }}
                    >
                      <Typography
                        variant='body2'
                        color='textSecondary'
                        className={classes.linkItems}
                        style={{
                          color:
                            history.location.pathname === '/'
                              ? theme.palette.pink.main
                              : 'white',
                        }}
                      >
                        HOME
                      </Typography>
                    </Link>
                    {navItems.map((item, index) => (
                      <Link
                        to={`/${item.toLowerCase()}`}
                        key={item}
                        style={{ textDecoration: 'none', marginRight: '2rem' }}
                      >
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          className={classes.linkItems}
                          style={{
                            color: history.location.pathname.includes(
                              item.toLowerCase()
                            )
                              ? theme.palette.pink.main
                              : 'white',
                          }}
                        >
                          {item}
                        </Typography>
                      </Link>
                    ))}
                  </Box>
                </Box>
              )}
            </Grid>
            <Box display='flex' alignItems='center'>
              {!prelaunch && (
                <Box hidden={matches} mr={2}>
                  {headerMenuExpanded ? (
                    <Grid onClick={() => dispatch(toggleHeaderMenuExpanded())}>
                      <img src={HamburgerMenuWhite} alt='cross' />
                    </Grid>
                  ) : (
                    <Grid onClick={() => dispatch(toggleHeaderMenuExpanded())}>
                      <img src={HamburgerMenuWhite} alt='hamburger' />
                    </Grid>
                  )}
                </Box>
              )}
              <Box hidden={headerMenuExpanded}>
                {(!prelaunch) && !active && tried ? (
                  <Button variant='contained' color='primary' onClick={connectMetamask}>
                    Connect {matches && 'Wallet'}
                  </Button>
                ) : prelaunch ? (
                  <Button variant='contained' color='primary'>
                    Claim Tokens
                  </Button>
                ):<Button variant='contained' color='primary' onClick={()=>history.push("/admin")}>
										View Dashboard
									</Button>}
              </Box>
              <Menu
                keepMounted
                open={headerMenuExpanded}
                onClose={() => dispatch(toggleHeaderMenuExpanded())}
                classes={{ paper: classes.paper }}
              >
                <MenuItem
                  onClick={() => handleClick('/')}
                  classes={{ root: classes.listItem }}
                >
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    className={classes.linkItems}
                    style={{
                      color:
                        history.location.pathname === '/'
                          ? theme.palette.pink.main
                          : 'white',
                    }}
                  >
                    HOME
                  </Typography>
                </MenuItem>
                {navItems.map((item, index) => (
                  <MenuItem
                    onClick={() => handleClick(`/${item.toLowerCase()}`)}
                    classes={{ root: classes.listItem }}
                    key={index}
                  >
                    <Typography
                      variant='body2'
                      color='textSecondary'
                      className={classes.linkItems}
                      style={{
                        color: history.location.pathname.includes(
                          item.toLowerCase()
                        )
                          ? theme.palette.pink.main
                          : 'white',
                      }}
                    >
                      {item}
                    </Typography>
                  </MenuItem>
                ))}
                <Box display='flex' justifyContent='center'>
                  <Button variant='contained' color='primary' onClick={connectMetamask}>
                    Connect {matches && 'Wallet'}
                  </Button>
                </Box>
              </Menu>
            </Box>
          </Grid>
        </Container>
      </header>
    </Box>
  );
};

export default Header;
