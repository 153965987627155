import { ethers } from "ethers";
import ethersServiceProvider from "./ethersServiceProvider";

export class GameService{

	 constructor(){}

	 async purchaseIngredient(type,tokenId,price){
		try{
			let purchaseTx;
			const tokenPrice=ethers.utils.parseEther(price);
			const tx=await ethersServiceProvider.approveUSDCDelegation(tokenPrice);
			await tx.wait();
			if(type==="1"){
				purchaseTx=await ethersServiceProvider.buyFixedPriceIngredient(tokenId,2); //2 index for USDC coin
			}else if(type==="2"){
				purchaseTx=await ethersServiceProvider.buyVariablePriceIngredient(tokenId,2); //2 index for USDC coin
			}
			await purchaseTx.wait();
			return purchaseTx;
		}catch(err){
			console.log(err,"Error in purchasing ingredients");
		}
	 }

	 async cookDish(tokenId,data){
		 try{
			await ethersServiceProvider.approveERC1155Delegation();
			const cookTx=ethersServiceProvider.cookDish(tokenId,data);
			return cookTx;
		 }catch(err){
			console.log(err,"Error in cooking Dish");
		 }
	 }
}

const gameService = new GameService();

export default gameService;