import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const AdminIntroSection = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Box
      textAlign='center'
      display='flex'
      flexDirection='column'
      alignItems='center'
      py='10vh'
    >
      <Typography variant='h1'>Dashboard</Typography>
      <Typography
        variant='body1'
        style={{
          maxWidth: '50%',
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud.
      </Typography>
    </Box>
  );
};

export default AdminIntroSection;
